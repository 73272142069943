<template>
    <b-row>                
        <b-col md="12" v-for="(r, i) in questions" :key="i">
            <b-card-text>{{ r.titre }}</b-card-text>
            <b-row>
                <b-col md="4" v-for="(d, di) in r.data" :key="di">
                    <b-form-group>                        
                        <div v-if="d.options === false">
                            <p class="font-weight-bold">{{ d.request }}</p>
                            <div class="mt-2" v-if="d.field != undefined">
                                <b-form-input :type="d.field.type" v-model="projet.etude[d.field.key]" />
                            </div>
                        </div>
                        <div class="mb-3" v-else>
                            <span class="font-weight-bold">{{ d.request }}</span>
                            <b-form-radio-group size="lg" v-model="projet.etude[d.key]" :options="d.options" value-field="item" text-field="name" required></b-form-radio-group>
                            <div class="mt-2" v-if="d.field != undefined && projet.etude[d.key] === 'Oui'">
                                <b-form-input :type="d.field.type" v-model="projet.etude[d.field.key]" />
                            </div>
                        </div>                        
                    </b-form-group>
                </b-col>
            </b-row>
        </b-col>
        <b-col md="12">
            <b-form-group label="Commentaire">
                <b-form-textarea v-model="projet.etude.commentaire" rows="1">
                </b-form-textarea>
            </b-form-group>
        </b-col>
        <b-col md="12">
            <p class="font-weight-bold"> Votre budget est entre <Input type="price" v-model="projet.etude.min_budget" class="input-budget" required/> et <Input type="price" v-model="projet.etude.max_budget" class="input-budget" required/>
            </p>
        </b-col>                
    </b-row>
</template>
<script>
import Input from '@/components/InputForm';
export default {
  name: "Etude",  
  components:{Input},
  props:{
    projet:{      
      required: true
    },    
  },
  data(){
    return {      
    }
  },
  computed:{  
    questions(){        
        if(this.projet.etude.v === undefined){
            this.projet.etude.v === 2;
        }        
        var q = this.$api.params.QuestionEtude.filter(t => t.v === this.projet.etude.v);        
        return q;
    }  
  },
  methods: {    
  },
  beforeMount() {    
  },
  mounted() {        
  }
}
</script>
<style>
.input-budget {
  display: inline-block;
  max-width: 80px;
}
.custom-radio:has(> .need-validation) { 
  border-bottom: 1.5px solid red;
  padding-bottom: 10px;
 }
</style>