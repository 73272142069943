<template>
  <div>
    <b-row>
      <b-col md="12" class="text-right">
        <b-button v-b-tooltip.hover title="Ajouter un document" variant="primary" class="btn-icon" size="sm" v-b-modal.import-file>
          <i class="fas fa-plus"></i>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" v-for="(d, i) in docs" :key="i">
        <b-card class="card-search text-center mb-3 w-100">
          <i class="fas fa-4x d-block" :class="getIcon(d.titre)"></i><br>
          <span class="h6 mt-2">{{d.titre}}</span><br>
          <span>{{$api.getParam('GEDType', d.type_id).titre}}</span><br>
          <div class="mt-2">
            <b-button-group size="sm">
              <b-button class="btn-icon" variant="primary" :href="$api.link(d.url)" target="_blank">
              <i class="fas fa-eye"></i>
            </b-button>
            <!-- <b-button v-b-tooltip.hover title="Supprimer la ligne" class="btn-icon" variant="danger" @click="deleteFile(d)">
              <i class="fas fa-trash" v-if="d.status === 1"></i>
              <i v-else class="fas fa-spinner fa-spin"></i>
            </b-button> -->
            </b-button-group>            
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="import-file" id="import-file" hide-footer>
      <template #modal-title>
        Ajouter votre document
      </template>
      <div class="d-block text-center">
        <b-form-group label="Votre fichier">
          <b-form-file @change="handleFile" placeholder="Choisissez votre document" drop-placeholder="Drop file here..."></b-form-file>
        </b-form-group>
        <b-form-group label="Categorie">
          <Input v-model="type_id" type="select-model" :multiple="false" :options="$api.params.GedType" />
        </b-form-group>
      </div>
      <b-button class="mt-3" variant="success" @click="addFile()">
        <span v-if="load_file === false">Sauvegarder</span>
        <i v-else class="fas fa-spinner fa-spin"></i>
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import Input from './InputForm';
export default {
  name: "GED",
  components:{Input},
  props:{
    model: {
      type: String,
      required: true
    },
    model_id: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    load_file:false,
    docs:[],
    type_id: null,
    file:null,
    file_name: null
  }),
  methods: {
    getIcon(titre) {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(titre)[1];
      var icon = 'fa-file'
      switch (ext) {
        case 'pdf':
        icon = 'fa-file-pdf';
        break;
        case 'docx':
        case 'doc':
        icon = 'fa-file-word';
        break;
        case 'xlsx':
        case 'xls':
        icon = 'fa-file-excel';
        break;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'svg':
        icon = 'fa-file-image';
        break;
        case 'mp4':
        case 'mkv':
        icon = 'fa-file-video';
        break;
      }
      return icon;
    },
    init(){
      this.$api.ajax(`/ged/liste?model=${this.model}&model_id=${this.model_id}`, null, res => {
        if(res.status === true){
          this.docs = res.data
        }
      });
    },
    deleteFile(doc){
      doc.status = 0;
      this.$api.ajax('/ged/delete/'+doc.id, null, res => {
        if(res.status === true){
          this.init();
        }
      })
    },
    handleFile(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      this.load_file = true;
      reader.onload = (e) => {
        this.file_name = fileUploaded.name;
        this.file = e.target.result
        this.load_file = false;
      }
      reader.readAsDataURL(fileUploaded);
    },
    addFile(){
      if(this.load_file === true){
        return false;
      }
      this.load_file = true;
      var params = {
        file: this.file,
        file_name: this.file_name,
        ged: {
          model: this.model,
          model_id: this.model_id,
          type_id: this.type_id
        }
      }
      this.$api.ajax('/ged/upload', params, res => {
        if(res.status === true){
          this.file = null
          this.file_name = null;
          this.type_id = null;
          this.load_file = false;
          this.init();
          this.$refs['import-file'].hide();
        }
      })
    }
  },
  mounted() {
    //do something after mounting vue instance
    this.init();
  }
}
</script>
<style lang="scss" scoped>
</style>
